module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/404","/job-alerts","/sitemap"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHG5RZM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"AccroPress"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://31544eb084ae44539700cd4d7e0ca55d@sentry.accropress.com/5","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jobs at the Woodland Trust","short_name":"Jobs at the Woodland Trust","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
